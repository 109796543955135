import React, { useState, useEffect } from "react";
import { Grid, Paper, Stack, IconButton, Button } from "@mui/material";
import Modifiedinputfield from "../Components/Modifiedinputfield";
import Modifieddate from "../Components/Modifieddate";
import Modifiedtextarea from "../Components/Modifiedtextarea";
import Modifiedbutton from "../Components/Modifiedbutton";
import Modifiedbrowserfiles from "../Components/Modifiedbrowserfiles";
import { editsuccessstory_api } from "../Apiservices/Adminservices";
import { enqueueSnackbar } from "notistack";
import { viewstyles } from "../Styles/Component_styles";
import CloseIcon from "@mui/icons-material/Close";

const Editstorycomp = ({ color_exports, story, close }) => {
  const viewstyle = viewstyles();
  const [deletedImages, setDeletedImages] = useState([]);
 
  const [formData, setFormData] = useState({
    brideName: story.brideName || "",
    groomName: story.groomName || "",
    email: story.email || "",
    engagementDate: story.engagementDate || "",
    marriageDate: story.marriageDate || "",
    successStory: story.successStory || "",
    deletedImages: (story.successStoryImages ?? []).map((image) => image.image1 || image.image2 || image.image3 || image.image4 || image.image5) || [],
    images: (story.successStoryImages ?? []).map(
      (image) =>
        `${story.storyBaseUrl}/${image.image1 || image.image2 || image.image3 || image.image4 || image.image5}`
    ) || [],
  });
  useEffect(() => {
    setFormData({
      brideName: story.brideName || "",
      groomName: story.groomName || "",
      email: story.email || "",
      engagementDate: story.engagementDate || "",
      marriageDate: story.marriageDate || "",
      successStory: story.successStory || "",
      images: (story.successStoryImages ?? []).map(
        (image) =>
          `${story.storyBaseUrl}/${image.image1 || image.image2 || image.image3 || image.image4 || image.image5}`
      ),
    });
  }, [story]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (name, date) => {
    // Check if the date is not null or undefined
    if (date) {
      // Convert the date to ISO 8601 format
      const isoDate = date.toISOString();
      setFormData({ ...formData, [name]: isoDate });
    } else {
      setFormData({ ...formData, [name]: "" });
    }
  };


  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    
    // Define allowed file types
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
  
    // Filter out files that are not images
    const validFiles = files.filter((file) => allowedTypes.includes(file.type));
    
    if (validFiles.length !== files.length) {
      enqueueSnackbar("Only JPG, JPEG & PNG are allowed", { variant: "error" });
    }
    
    // Check if the total number of images does not exceed the limit
    if (validFiles.length + formData.images.length > 5) {
      enqueueSnackbar("Cannot upload more than 5 images", { variant: "error" });
      return;
    }
  
    setFormData((prevFormData) => ({
      ...prevFormData,
      images: [...prevFormData.images, ...validFiles],
    }));
  };
  



  const handleRemoveImage = (index) => {
    setFormData((prevFormData) => {
      const updatedImages = [...prevFormData.images];
      const removedImageLabel = `image${index + 1}`;
      updatedImages.splice(index, 1);
      
      setDeletedImages((prevDeletedImages) => {
        
        if (prevDeletedImages.includes(removedImageLabel)) {
          let newLabelIndex = index + 1;
          while (prevDeletedImages.includes(`image${newLabelIndex}`)) {
            newLabelIndex++;
          }
          return [...prevDeletedImages, `image${newLabelIndex}`];
        } else {
          return [...prevDeletedImages, removedImageLabel];
        }
      });
  
      return { ...prevFormData, images: updatedImages };
    });
  };
  
  
  const handleFormSubmit = async () => {
    const currentDate = new Date().toISOString(); // Get current date in ISO format
    const newErrors = {};
  
    if (!formData.engagementDate) {
      newErrors.engagementDate = "Engagement Date is required";
    } else if (formData.engagementDate > currentDate) {
      newErrors.engagementDate = "Engagement Date cannot be in the future";
    }
  
    if (!formData.marriageDate) {
      newErrors.marriageDate = "Marriage Date is required";
    } else if (formData.marriageDate > currentDate) {
      newErrors.marriageDate = "Marriage Date cannot be in the future";
    } else if (formData.marriageDate < formData.engagementDate) {
      newErrors.marriageDate = "Marriage Date cannot be before Engagement Date";
    }
  
    // If there are any validation errors, show them and return early
    if (Object.keys(newErrors).length > 0) {
      Object.values(newErrors).forEach((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
      return;
    }
  
    if (formData.images.length === 0) {
      enqueueSnackbar("At least one image should be uploaded", { variant: "error" });
      return;
    }
  
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("successStoryId", story.successStoryId);
      formDataToSend.append("brideName", formData.brideName);
      formDataToSend.append("groomName", formData.groomName);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("engagementDate", formData.engagementDate);
      formDataToSend.append("marriageDate", formData.marriageDate);
      formDataToSend.append("successStory", formData.successStory);
      formDataToSend.append("deletedImages", JSON.stringify(deletedImages));
      formData.images.forEach((image) => {
        formDataToSend.append("images", image);
      });
  
      const response = await editsuccessstory_api(formDataToSend);
      if (response?.message === "SuccessStory updated successFully") {
        enqueueSnackbar("SuccessStory updated successFully", {
          variant: "success",
        });
        setFormData({
          brideName: "",
          groomName: "",
          email: "",
          engagementDate: "",
          marriageDate: "",
          successStory: "",
          images: [],
        });
        setDeletedImages([]);
      } else {
        console.error("Failed to post story:", response?.message);
        enqueueSnackbar(response?.message || "Failed to post story", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      enqueueSnackbar("An error occurred while updating the story", { variant: "error" });
    }
  };
  
  


  return (
    <Grid item md={12}>
      <Paper elevation={5} className={viewstyle.paper}>
        <Grid container direction={"column"} spacing={2}>
          {/* Form inputs */}
          <Grid
            item
            mt={-1}
            fontSize={"16px"}
            sx={{ color: color_exports.primary_color }}
          >
            Edit Success Story
          </Grid>
          <Grid item container mt={-0.5} spacing={2}>
            <Grid item md={6}>
              <Stack spacing={1}>
                <Grid
                  item
                  fontSize={"14px"}
                  sx={{ color: color_exports.primary_color }}
                >
                  Bride Name (Female)
                </Grid>
                <Grid item>
                  <Modifiedinputfield
                    fullWidth
                    placeholder="Enter Here"
                    name="brideName"
                    value={formData.brideName}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Stack>
            </Grid>
            <Grid item md={6}>
              {/* Groom Name */}
              <Stack spacing={1}>
                <Grid
                  item
                  fontSize={"14px"}
                  sx={{ color: color_exports.primary_color }}
                >
                  Groom Name (Male)
                </Grid>
                <Grid item>
                  <Modifiedinputfield
                    fullWidth
                    placeholder="Enter Here"
                    name="groomName"
                    value={formData.groomName}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Stack>
            </Grid>
          </Grid>
          {/* Email input */}
          <Grid item mt={-0.5}>
            <Stack spacing={1}>
              <Grid
                item
                fontSize={"14px"}
                sx={{ color: color_exports.primary_color }}
              >
                Email
              </Grid>
              <Grid item>
                <Modifiedinputfield
                  fullWidth
                  placeholder="Enter Here"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </Grid>
            </Stack>
          </Grid>
          {/* Engagement and Marriage Date inputs */}
          <Grid item container spacing={2} mt={-0.5}>
            <Grid item md={6}>
              {/* Engagement Date */}
              <Stack spacing={1}>
                <Grid
                  item
                  fontSize={"14px"}
                  sx={{ color: color_exports.primary_color }}
                >
                  Engagement Date
                </Grid>
                <Grid item>
                  <Modifieddate
                    fullWidth
                    placeholder="Select"
                    name="engagementDate"
                    value={formData.engagementDate}
                    onChange={(date) =>
                      handleDateChange("engagementDate", date)
                    }
                  />
                </Grid>
              </Stack>
            </Grid>
            <Grid item md={6}>
              {/* Marriage Date */}
              <Stack spacing={1}>
                <Grid
                  item
                  fontSize={"14px"}
                  sx={{ color: color_exports.primary_color }}
                >
                  Marriage Date
                </Grid>
                <Grid item>
                  <Modifieddate
                    fullWidth
                    placeholder="Select"
                    name="marriageDate"
                    value={formData.marriageDate}
                    onChange={(date) => handleDateChange("marriageDate", date)}
                  />
                </Grid>
              </Stack>
            </Grid>
          </Grid>
          {/* Success Story input */}
          <Grid item mt={-0.5}>
            <Stack spacing={1}>
              <Grid
                item
                fontSize={"14px"}
                sx={{ color: color_exports.primary_color }}
              >
                Success story
              </Grid>
              <Grid item>
                <Modifiedtextarea
                  rows={4}
                  fullWidth
                  placeholder="Write here"
                  name="successStory"
                  value={formData.successStory}
                  onChange={handleInputChange}
                />
              </Grid>
            </Stack>
          </Grid>
          {/* File input for uploading images */}
          <Grid item mt={-0.5}>
            <Modifiedbrowserfiles
              placeholder="Share Happy Moments"
              onChange={handleFileChange}
            />
          </Grid>

          {/* Display uploaded images */}
          <Grid item container mt={-0.5} spacing={2}>
            {formData.images.map((image, index) => (

              <Grid item md={3} key={index} sx={{ position: "relative" }}>
                <img
                  src={typeof image === "object" ? URL.createObjectURL(image) : image}
                  alt={`Story Image ${index + 1}`}
                  style={{ width: "100px", height: "100px" }}
                />
                <IconButton
                  onClick={() => handleRemoveImage(index)}
                  sx={{ position: "absolute", top: 0, right: 0 }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            ))}
          </Grid>

          {/* Buttons */}
          <Grid item container justifyContent={"center"} gap={2}>
            <Modifiedbutton
              data={"Cancel"}
              bgcolor={"#F2F2F2"}
              styles={{ color: "#9F9F9F" }}
              onClick={close}
            />
            <Modifiedbutton data={"Update"} onClick={handleFormSubmit} />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Editstorycomp;