import Adminapiservice from "./Adminapiservice";
export const adminloginapi = async (payload) => {
  try {
    const res = await Adminapiservice(
      "n8ooY3PWvoLghWhSRuqx",
      // "adminLogin",
      payload,
      "POST",
      "application/json"
    );
    return res;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
export const admin_forgotpwd = async (payload) => {
  const res = await Adminapiservice(
    "cYyDQn7fzRbOlb",
    // "adminForgotPassword",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const admin_verify_otp = async (payload) => {
  const res = await Adminapiservice(
    "adminVerifyOtp",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const admin_resetpwd = async (payload) => {
  const res = await Adminapiservice(
    "adminResetPassword",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const admin_changepwd = async (payload) => {
  const res = await Adminapiservice(
    "adminChangePassword",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const getusers_api = async (payload) => {
  const res = await Adminapiservice(
    "getUsers",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const getguestusers_api = async (payload) => {
  const res = await Adminapiservice(
    "getGuestUsersList",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const getregisteredguestusers_api = async (payload) => {
  const res = await Adminapiservice(
    "getRegisteredGuestUsers",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const viewuser_api = async (payload) => {
  const res = await Adminapiservice(
    "getUserBasicInfo",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const viewuser_api2 = async (payload) => {
  const res = await Adminapiservice(
    "getDashboardList",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const manageUsers_api = async (payload) => {
  const res = await Adminapiservice(
    "manageUsers",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const postsuccessstory_api = async (payload) => {
  const res = await Adminapiservice(
    "postSuccessStoryByAdmin",
    payload,
    "POST",
    "multipart/form-data"
  );
  return res;
};
export const getSuccessStories_api = async (payload) => {
  const res = await Adminapiservice(
    "getSuccessStories",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const getSuccessStoriesbyId_api = async (
  successStoryId,
  accessToken
) => {
  const res = await Adminapiservice(
    `getSuccessStoryById`,
    accessToken,
    "POST",
    "application/json"
  );
  return res;
};
export const editsuccessstory_api = async (payload) => {
  const res = await Adminapiservice(
    "updateSuccessStory",
    payload,
    "POST",
    "multipart/form-data"
  );
  return res;
};
export const refreshtoken_api = async (payload) => {
  const res = await Adminapiservice(
    "adminRefreshToken",
    payload,
    "GET",
    "application/json"
  );
  return res;
};
export const createfaq_api = async (payload) => {
  const res = await Adminapiservice(
    "createFaq",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const getfaq_api = async (payload) => {
  const res = await Adminapiservice(
    "getFaqs",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const upsertContentManagement_api = async (payload) => {
  const res = await Adminapiservice(
    "updateContentManagement",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const getContentManagement_api = async (payload,defaultAuthKey) => {
  const res = await Adminapiservice(
    "getContentManagement",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
// export const downloadUserCsvData_api = async (payload) => {
//   const res = await Adminapiservice(
//     "downloadUserCsvData",
//     payload,
//     "GET",
//     "application/json"
//   );
//   return res;
// }
export const successstoryenable_api = async (payload) => {
  const res = await Adminapiservice(
    "updateAdminPreferences",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const reports_api = async (payload) => {
  const res = await Adminapiservice(
    "getReports",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const notifications_api = async (payload) => {
  const res = await Adminapiservice(
    "getNotifications",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const addsubadmin_api = async (payload) => {
  const res = await Adminapiservice(
    "addSubAdmin",
    payload,
    "POST",
    "multipart/form-data"
  );
  return res;
};
export const retrievesubadmins_Api = async (payload) => {
  const res = await Adminapiservice(
    "getAllSubAdmins",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const deletesubadmin_api = async (payload) => {
  const res = await Adminapiservice(
    "deleteSubAdmin",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const deleteExistingUserOffer_api = async (payload) => {
  const res = await Adminapiservice(
    "deleteExistingUserOffer",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const updatesubadmin_api = async (payload) => {
  const res = await Adminapiservice(
    "updateSubAdmin",
    payload,
    "POST",
    "multipart/form-data"
  );
  return res;
};
export const createpackage_api = async (payload) => {
  const res = await Adminapiservice(
    "createPackage",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const editpackage_api = async (payload) => {
  const res = await Adminapiservice(
    "updateAdminPackage",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const getpackages_api = async (payload) => {
  const res = await Adminapiservice(
    "getPackages",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const getcountries_api = async (payload) => {
  const res = await Adminapiservice("getCountries", "GET");
  return res;
};
export const getstates_api = async (payload) => {
  const res = await Adminapiservice(
    "getStates",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const getcities_api = async (payload) => {
  const res = await Adminapiservice(
    "getCities",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const getlocatedusers_api = async (accessToken) => {
  const res = await Adminapiservice(
    `getTopLocationAppUsage`,
    accessToken,
    "GET",
    "application/json"
  );
  return res;
};
export const creategroup_api = async (payload) => {
  const res = await Adminapiservice(
    "creategroupData",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const noofmatches_api = async (payload) => {
  const res = await Adminapiservice(
    "getMatchAnalytics",
    payload,
    "POST",
    "application/json"
  );
  return res;
};

export const revenuegenerated_api = async (payload) => {
  const res = await Adminapiservice(
    "getRevenueAnalytics",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const updateFaqOrders_api = async (payload) => {
  try {
    const res = await Adminapiservice(
      "updateFaqOrders",
      payload,
      "POST",
      "application/json"
    );
    return res;
  } catch (error) {
    console.error("Error in updateFaqOrders_api:", error);
    throw error;  // Re-throw to handle it where you call the function
  }
};

export const groupnameexists_api = async (payload) => {
  const res = await Adminapiservice(
    "isGroupNameExists",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const itemnameexists_api = async (payload) => {
  const res = await Adminapiservice(
    "isItemNamesExists",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const getgroupnames_api = async (accessToken) => {
  const res = await Adminapiservice(
    `getGroupsData`,
    accessToken,
    "GET",
    "application/json"
  );
  return res;
};
export const getitemnames_api = async (payload) => {
  const res = await Adminapiservice(
    "getGroupItemsData",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const additemname_api = async (payload) => {
  const res = await Adminapiservice(
    "createItemData",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const sendmailreport_api = async (payload) => {
  const res = await Adminapiservice(
    "sendMailReport",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const updateitemdata_api = async (payload) => {
  const res = await Adminapiservice(
    "updateItemData",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const getUsersAnalytics_api = async (payload) => {
  const res = await Adminapiservice(
    "getUsersAnalytics",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const getMembershipAnalytics_api = async (payload) => {
  const res = await Adminapiservice(
    "getMembershipAnalytics",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const updatefaq_api = async (payload) => {
  const res = await Adminapiservice(
    "updateFaq",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const deletefaq_api = async (payload) => {
  const res = await Adminapiservice(
    "deleteFaq",
    payload,
    "POST",
    "application/json"
  );
  return res;
};

export const deactivateAccountOtp_api = async (payload) => {
  const res = await Adminapiservice(
    // "NYSwknRJQQMrDQy1lMnX",
    "deactivateAccountOtp",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
export const deleteMyAccount_api = async (payload) => {
  const res = await Adminapiservice(
    "oQ2ZjFST9b",
    // "deleteMyAccount",
    payload,
    "POST",
    "application/json"
  );
  return res;
};