import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import { adminloginstyles } from "../Styles/Admin_login_styles";
import sidebarlogo from "../assets/Images/logowithtm.svg";

const Childsafety = () => {
  const adminstyles = adminloginstyles();

  return (
    <Grid container p={10} pt={4} backgroundColor={"#b8003e"} color={"#fff"}> 
      <Grid item md={12} container className={adminstyles.iconrelative}>
        {/* Centered Logo */}
        <Box display="flex" flexDirection="column" alignItems="center">
  <img src={sidebarlogo} alt="JanataMatrimony Logo" />
  <Typography variant="h5" className={adminstyles.childsafetyhead} fontWeight={700} mt={2}>
    Child Safety Compliance Statement for JanataMatrimony
  </Typography>
</Box>

        <Box mt={4}>
          <Typography variant="body1" gutterBottom color={"#fff"}>
            JanataMatrimony is committed to providing a secure and trustworthy platform for matrimonial connections while ensuring full compliance with Google Play’s Child Safety Standards.
          </Typography>
          <Typography variant="h6" mt={4} color={"#fff"}><b>1. Age Restriction & Appropriate Content</b></Typography>
          <Typography variant="body2" mt={2} color={"#fff"}>
            - JanataMatrimony is designed exclusively for users aged 18 and above.<br/><br/>
            - The app does not contain any content that is explicit, violent, or inappropriate for minors.<br/><br/>
            - We actively monitor and review user-generated content to prevent the presence of unsuitable material.
          </Typography>
          <Typography variant="h6" mt={4} color={"#fff"}><b>2. User Interaction & Safety Measures</b></Typography>
          <Typography variant="body2" mt={2} color={"#fff"}>
            - <strong>Profile Verification:</strong> We encourage users to provide accurate details and implement verification checks to enhance authenticity.<br/><br/>
            - <strong>Moderation:</strong> All profile data, images, and interactions are reviewed to prevent fraudulent or inappropriate behavior.<br/><br/>
            - <strong>Reporting & Blocking:</strong> Users can report suspicious profiles or block unwanted interactions at any time.
          </Typography>
          <Typography variant="h6" mt={4} color={"#fff"}><b>3. Compliance with Child Safety Guidelines</b></Typography>
          <Typography variant="body2" mt={2} color={"#fff"}>
            - <strong>No Minors Allowed:</strong> JanataMatrimony does not allow minors (under 18) to register or use the app.<br/><br/>
            - <strong>Secure Data Handling:</strong> We adhere to COPPA (Children’s Online Privacy Protection Act) and GDPR-K (General Data Protection Regulation for Kids) to ensure secure data collection and privacy.<br/><br/>
            - <strong>Parental Guidance Encouraged:</strong> Although the app is strictly for adults, parents are encouraged to report any suspected underage users.
          </Typography>
          <Typography variant="h6" mt={4} color={"#fff"}><b>4. Safe & Secure Matchmaking Features</b></Typography>
          <Typography variant="body2" mt={2} color={"#fff"}>
            - <strong>No Random Pairing:</strong> Matches are suggested based on community preferences, religion, and compatibility, rather than random selection.<br/><br/>
            - <strong>No Location Sharing:</strong> JanataMatrimony does not request, collect, or share real-time location data with other users.<br/><br/>
            - <strong>Strict Community Guidelines:</strong> We enforce a zero-tolerance policy against harassment, cyberbullying, or any harmful behavior.
          </Typography>
          <Typography variant="h6" mt={4} color={"#fff"}><b>5. Compliance with Google Play Policies</b></Typography>
          <Typography variant="body2" mt={2} color={"#fff"}>
            - JanataMatrimony has completed Google Play’s Child Safety Declaration and fully complies with the Child Safety Standards Policy.<br/><br/>
            - Our regular audits and updates ensure continuous adherence to Google Play’s latest safety requirements.
          </Typography>
          <Typography variant="h6" mt={4} color={"#fff"}><b>6. Contact for Safety Concerns</b></Typography>
          <Typography variant="body2" mt={2} color={"#fff"}>
            For any questions or concerns regarding child safety on our platform, please contact us at:<br/><br/>
            <b>Email:</b> support@janatamatrimony.com
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Childsafety;
