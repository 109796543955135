import { makeStyles } from "@mui/styles";
import { color_exports } from "./Color_exports";

export const modifiedtable_styles = makeStyles({
  stickyHeader1: {
    width: "fit-content",
    backgroundImage: `linear-gradient(to top, #FFFFFF, #F9CDDC)`,
    padding: "0px 0px 0px 10px",
    borderTopLeftRadius: "1rem",
    borderBottom: "2px solid #fff",
  },
  stickyHeader2: {
    // minWidth: column.minWidth,
    backgroundImage: `linear-gradient(to top, #FFFFFF, #F9CDDC)`,
    borderBottom: "3px solid #fff",
    padding: "0px 16px",
    color: "#333333",
    fontWeight: 400,
    fontSize: "16px",
    height: "46px",
    "&::before": {
      content: '""',
      position: "absolute",
      inset: 0,
      borderRadius: 0,
      background: "linear-gradient(to top, #FFFFFF, #B8003E)",
      zIndex: -1,
      width: "1px",
      right: "0px !important",
      left: "unset",
    },
  },
  stickyHeader3: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: 700,
  },
  stickyHeader31: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: 700,
  },
  tablecell1: {
    borderRight: "1px solid #33333333",
    borderBottom: "2px solid #fff",
    marginLeft: "1rem",
  },
  tablecell2: {
    width: "fit-content",
    borderBottom: "2px solid #fff",
    padding: "0px 0px 0px 10px",
  },
  tablecontainer1: {
    borderRadius: "1rem",
    overflow: "auto",
  },
});
