import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Tabs,
  Tab,
  Grid,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Box,
  CircularProgress, // Import CircularProgress
} from "@mui/material";
import Modifiedtable from "../../Components/Modifiedtable";
import {
  castingcall_columns,
  regesteredcolumns,
  guestusers_columns,
  existinguserscolumns,
} from "../../Components/Tablecolums";
import Modifiedsearchbar from "../../Components/Modifiedsearchbar";
import ReactPaginate from "react-paginate";
import RightArrow from "../../Components/RightArrow";
import LeftArrow from "../../Components/LeftArrow";
import Modifiedbutton from "../../Components/Modifiedbutton";
import { color_exports } from "../../Styles/Color_exports";
import {
  getusers_api,
  getguestusers_api,
  getregisteredguestusers_api,
} from "../../Apiservices/Adminservices";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { saveAs } from "file-saver";
import { debounce } from "lodash";
import Guestusertable from "../../Components/Guestusertable";
import { userdetail_styles } from "../../Styles/Adminuser_details_styles";
import dropdownicon from "../../assets/Images/dropdownicon.png";
import janatauser from "../../assets/Images/janatauser.jpg";
import BlockorUnblock from "../../Models/Block_or_Delet";
import axios from "axios";
import { StorageClient } from "../../Helpers/Helperfunctions";
import Existinguserslist from "../../Components/Existinguserslist";
import { useLocation } from "react-router-dom";

const AdminUsermanagement = () => {
 
  const location = useLocation();
  const [tabledata, setTabledata] = useState([]);
  const [guestusertabledata, setGuestusertabledata] = useState([]);
  const [registeredtabledata, setRegisteredtabledata] = useState([]);
  const [existinguserstabledata, setExistinguserstabledata] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(() => {
    // Initialize pageNumber from location state if available
    return location.state?.pageNumber && location.state.pageNumber > 0
      ? location.state.pageNumber
      : 1;
  });
  const [searchText, setSearchText] = useState("");
  const [sortValue, setSortValue] = useState("serialNo");
  const [sortBy, setSortBy] = useState("DESC");
  const [tabIndex, setTabIndex] = useState(0);
  const [guestType, setGuestType] = useState("registered");
  const [anchorEl, setAnchorEl] = useState(null);
  const latestSearchText = useRef(searchText);
  const usermanagementstyle = userdetail_styles();
  const [blockPopupOpen, setBlockPopupOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [userCount, setUserCount] = useState(0);
  const [count, setCount] = useState(0);
  const [guestcount, setGuestcount] = useState(0);
  const [existingusercount, setExistingusercount] = useState(0);
  const [loading, setLoading] = useState(false); // Add loading state

  const calculateAge = (dob) => {
    const birthDate = moment(dob, "YYYY-MM-DD");
    const today = moment();
    const age = today.diff(birthDate, "years");
    return age;
  };

  const handleSort = (columnId) => {
    if (columnId === sortValue) {
      setSortBy(sortBy === "ASC" ? "DESC" : "ASC");
    } else {
      setSortValue(columnId);
      setSortBy(sortBy);
    }
  };
  const fetchUserData = useCallback(
    async (pagenumber) => {
      setLoading(true); // Set loading to true before fetching
      try {
        const min = (pagenumber - 1) * pageSize;
        const max = pageSize;

        const req = {
          min: min,
          max: max,
          sortValue: sortValue,
          sortBy: sortBy,
          type: 1,
        };

        if (latestSearchText.current.trim()) {
          req.searchText = latestSearchText.current.trim().toLowerCase();
        }

        const response = await getusers_api(req);

        if (response?.message === "Successfully retrived Users data") {
          const formattedData = response.data.rows.map((row, index) => {
            const imageUrl =
              row.profileImages.length && row.profileImages[0]?.image1
                ? `${response.data.imageBaseUrl}${row.profileImages[0].image1}`
                : janatauser;

            const packageStatus =
              moment().utc().format("YYYY-MM-DD HH:mm:ss") >
              moment(row.endDate).format("YYYY-MM-DD HH:mm:ss")
                ? "Expired"
                : "Active";

            return {
              "S.NO": min + index + 1,
              ID: `JM00100${row.serialNo}`,
              Name: (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={imageUrl}
                    alt="Profile"
                    className={usermanagementstyle.prflimg}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = janatauser;
                    }}
                  />
                  <span>{row.firstName}</span>
                </div>
              ),
              Age: calculateAge(row.dateOfBirth),
              Gender: row.gender,
              Religion: row.religion || "Not specified",
              Createdon: moment(row.registrationDate).format("DD-MM-YYYY"),
              Subscriptionstarted: moment(row.createdAt).format("DD-MM-YYYY"),
              Subscriptionended: moment(row.endDate).format("DD-MM-YYYY"),
              Location: row.permanentCity,
              Package: row.packageData?.packageTitle || "Not specified",
              PackageStatus: packageStatus,
              userId: row.userId,
              blockStatus: row.blockStatus,
            };
          });

          setTabledata(formattedData);
          setPageCount(Math.ceil(response.data.count / pageSize));
          setUserCount(response.data.count);
        } else {
          console.error("Failed to fetch users:", response?.message);
          enqueueSnackbar(response?.message || "Failed to fetch users", {
            variant: "error",
          });
        }
      } catch (err) {
        console.error("Error:", err);
        enqueueSnackbar(err.message, { variant: "error" });
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    },
    [pageSize, sortBy, sortValue]
  );

  const fetchGuestUserData = useCallback(
    async (pagenumber) => {
      setLoading(true);
      try {
        const req = { min: (pagenumber - 1) * pageSize, max: pageSize };
        if (latestSearchText.current.trim()) {
          req.searchText = latestSearchText.current.trim().toLowerCase();
        }
        const response = await getguestusers_api(req);
        if (response?.message === "Successfully retrived Users data") {
          const formattedData = response.data.rows.map((row, index) => ({
            userId: row.id,
            "S.NO": (pagenumber - 1) * pageSize + index + 1,
            ID: `JM00100${row.serialNo}`,
            Name: `${row.name}`,
            Email: row.email,
            "Phone Number": row.phoneNumber || "Not available",
            Religion: row.religion || "Not available",
            "Looking For": row.lookingFor || "Not available",
            "Existing User Status":
              row.existingUserStatus === -1 ? "Removed" : row.existingUserStatus || "-",
            type: 2,
            blockStatus: row.blockStatus,
          }));
          setGuestusertabledata(formattedData);
          setGuestcount(response.data.count);
          setPageCount(Math.ceil(response.data.count / pageSize));
        } else {
          console.error("Failed to fetch guest users:", response?.message);
          enqueueSnackbar(response?.message || "Failed to fetch guest users", {
            variant: "error",
          });
        }
      } catch (err) {
        console.error("Error:", err);
        enqueueSnackbar(err.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    },
    [pageSize]
  );

  const fetchRegisteredGuestUserData = useCallback(
    async (pagenumber) => {
      setLoading(true);
      try {
        const req = {
          min: (pagenumber - 1) * pageSize,
          max: pageSize,
        };

        if (latestSearchText.current.trim()) {
          req.searchText = latestSearchText.current.trim().toLowerCase();
        }

        const response = await getregisteredguestusers_api(req);

        if (response?.message === "Successfully retrived Users data") {
          const formattedData = response.data.rows.map((row, index) => ({
            userId: row.userId,
            "S.NO": (pagenumber - 1) * pageSize + index + 1,
            ID: `JM00100${row.serialNo}`,
            Name: `${row.firstName}${row.lastName ? ` ${row.lastName}` : ""}`,
            Email: row.email,
            "Phone Number": row.phoneNumber || "Not available",
            Religion: row.religion || "Not available",
            "Looking For": row.lookingFor || "Not available",
            "Existing User Status":
              row.existingUserStatus === -1 ? "Removed" : row.existingUserStatus || "-",
            type: 1,
            blockStatus: row.blockStatus,
          }));
          setRegisteredtabledata(formattedData);
          setCount(response.data.count);
          setPageCount(Math.ceil(response.data.count / pageSize));
        } else {
          console.error(
            "Failed to fetch registered guest users:",
            response?.message
          );
          enqueueSnackbar(
            response?.message || "Failed to fetch registered guest users",
            { variant: "error" }
          );
        }
      } catch (err) {
        console.error("Error:", err);
        enqueueSnackbar(err.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    },
    [pageSize]
  );

  const fetchExistingUserData = useCallback(
    async (pagenumber) => {
      setLoading(true);
      try {
        const req = {
          min: (pagenumber - 1) * pageSize,
          max: pageSize,
        };

        if (latestSearchText.current.trim()) {
          req.searchText = latestSearchText.current.trim().toLowerCase();
        }

        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getAllExistingUsers`,
          req,
          {
            headers: {
              Authorization: `Bearer ${StorageClient.getItem("token")}`,
            },
          }
        );

        if (
          response?.data?.message === "Existing Users retrieved successFully"
        ) {
          const formattedData = response.data.data.rows.map((row, index) => ({
            "S.NO": (pagenumber - 1) * pageSize + index + 1,
            Email: row.email || "Not available",
            "Created On": moment(row.createdAt).format("DD-MM-YYYY"),
          }));

          setExistinguserstabledata(formattedData);
          setExistingusercount(response.data.data.count);
          setPageCount(Math.ceil(response.data.data.count / pageSize));
        } else {
          enqueueSnackbar(
            response?.message || "Failed to fetch existing users",
            { variant: "error" }
          );
        }
      } catch (err) {
        console.error("Error fetching existing users:", err);
        enqueueSnackbar(err.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    },
    [pageSize]
  );

  const debouncedFetchData = useCallback(
    debounce((pagenumber) => {
      if (tabIndex === 0) {
        fetchUserData(pagenumber);
      } else if (guestType === "registered") {
        fetchRegisteredGuestUserData(pagenumber);
      } else if (guestType === "notregistered") {
        fetchGuestUserData(pagenumber);
      } else if (guestType === "existing") {
        fetchExistingUserData(pagenumber);
      }
    }, 500),
    [
      
      tabIndex,
      guestType,
    ]
  );

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    setPageNumber(selectedPage);
    debouncedFetchData(selectedPage);
  };

  useEffect(() => {
    latestSearchText.current = searchText;
    debouncedFetchData(pageNumber);
  }, [searchText, pageNumber]);
  

  useEffect(() => {
    // setPageNumber(1); 
    if (tabIndex === 0) {
      // fetchUserData(1);
      fetchUserData( location.state?.pageNumber ? location.state?.pageNumber: pageNumber );
    } else if (guestType === "registered") {
      setPageNumber(1); 
      fetchRegisteredGuestUserData(1);
    } else if (guestType === "notregistered") {
      setPageNumber(1); 
      fetchGuestUserData(1);
    } else if (guestType === "existing") {
      setPageNumber(1); 
      fetchExistingUserData(1);
    }
  }, [tabIndex, guestType, fetchUserData, fetchGuestUserData, fetchRegisteredGuestUserData, fetchExistingUserData]);

  
  useEffect(() => {
    if (location.state?.pageNumber && location.state.pageNumber !== pageNumber) {
      setPageNumber(location.state.pageNumber);
    }
  }, [location.state?.pageNumber]);

  const handleDownloadClick = async () => {
    const token = StorageClient.getItem("token");

    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await axios.get(
        `${backendUrl}/downloadUserCsvData`,
        {
          headers: {
            Authorization: `${token}`,
          },
          responseType: "blob",
        }
      );

      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type: "text/csv;charset=utf-8",
        });
        saveAs(blob, "users-data.csv");
        enqueueSnackbar("File downloaded successfully", { variant: "success" });
      } else {
        console.error(
          `Failed to download file. Status: ${response.status} - Status Text: ${response.statusText}`
        );
        enqueueSnackbar(`Failed to download data: ${response.statusText}`, {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error during download:", error);
      enqueueSnackbar(
        `Failed to download data: ${error.message || "Unknown error"}`,
        { variant: "error" }
      );
    }
  };
  const handleGuestTypeChange = (type) => {
    setGuestType(type);
    setAnchorEl(null);
    setPageNumber(1);
  };

  const handleBlockOrUnblock = (row) => {
    setSelectedRow(row);
    setBlockPopupOpen(true);
  };

  const handleBlockOrUnblockClose = () => {
    setSelectedRow(null);
    setBlockPopupOpen(false);
  };

  const handleDownloadClick1 = async () => {
    const token = StorageClient.getItem("token");

    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const userType = guestType === "registered" ? 1 : 2;

      const response = await axios.post(
        `${backendUrl}/downloadGuestUsersCsvData`,
        { type: userType },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );

      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type: "text/csv;charset=utf-8",
        });
        saveAs(blob, "users-data.csv");
        enqueueSnackbar("File downloaded successfully", { variant: "success" });
      } else {
        console.error(
          `Failed to download file. Status: ${response.status} - Status Text: ${response.statusText}`
        );
        enqueueSnackbar(`Failed to download data: ${response.statusText}`, {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error during download:", error);
      enqueueSnackbar(
        `Failed to download data: ${error.message || "Unknown error"}`,
        { variant: "error" }
      );
    }
  };
  const handleDownloadClick2 = async () => {
    const token = StorageClient.getItem("token");

    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;

      const response = await axios.post(
        `${backendUrl}/downloadExistingUsersCsvData`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );

      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type: "text/csv;charset=utf-8",
        });
        saveAs(blob, "existing-users-data.csv");
        enqueueSnackbar("File downloaded successfully", { variant: "success" });
      } else {
        console.error(
          `Failed to download file. Status: ${response.status} - Status Text: ${response.statusText}`
        );
        enqueueSnackbar(`Failed to download data: ${response.statusText}`, {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error during download:", error);
      enqueueSnackbar(
        `Failed to download data: ${error.message || "Unknown error"}`,
        { variant: "error" }
      );
    }
  };
  useEffect(() => {
    const handleBeforeUnload = () => {
      window.history.replaceState(null, "", location.pathname);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  return (
    <>
      <Grid item container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Tabs value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)} aria-label="User management tabs" className={usermanagementstyle.tabstyles}>
            <Tab label="Members" />
            <Tab label={
              <Box display="flex" alignItems="center">
                {guestType === "registered" ? "Guest Registered Users" : guestType === "existing" ? "Existing Users" : "Guest Users"}

                <IconButton aria-controls="guest-menu" aria-haspopup="true" onClick={(e) => setAnchorEl(e.currentTarget)} size="small">
                  <img src={dropdownicon} alt="dropdownicon" />
                </IconButton>
              </Box>
            }
            />
          </Tabs>
          <Menu id="guest-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
            <MenuItem onClick={() => handleGuestTypeChange("registered")}>
              Guest Registered Users
            </MenuItem>
            <MenuItem onClick={() => handleGuestTypeChange("notregistered")}>
              Guest Users
            </MenuItem>
            <MenuItem onClick={() => handleGuestTypeChange("existing")}>
              Existing Users
            </MenuItem>
          </Menu>
        </Grid>
        <Grid item container md="auto" gap={2}>
          <Grid item>
            {tabIndex === 0 ? (
              <Modifiedsearchbar placeholder="Search with Name, Email, Location, JMID" value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  setPageNumber(1);
                  debouncedFetchData(1);
                }}
              />
            ) : guestType === "existing" ? (
              <Modifiedsearchbar
                placeholder="Search with Email, Date"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  setPageNumber(1);
                  debouncedFetchData(1);
                }}
              />
            ) : (
              <Modifiedsearchbar
                placeholder="Search with Name, Email, Phone Number"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  setPageNumber(1);
                  debouncedFetchData(1);
                }}
              />
            )}
          </Grid>
        </Grid>
        <Grid item container md justifyContent="end">
          {tabIndex === 0 ? (
            <Modifiedbutton
              data={"Download as excel"}
              sheight="2.5rem"
              swidth="11rem"
              onClick={handleDownloadClick}
            />
          ) : guestType !== "existing" ? (
            <Modifiedbutton
              data={"Download as Excel"}
              sheight="2.5rem"
              swidth="11rem"
              onClick={handleDownloadClick1}
            />
          ) : (
            <Modifiedbutton
              data={"Download Existing Data"}
              sheight="2.5rem"
              swidth="11rem"
              onClick={handleDownloadClick2}
            />
          )}
        </Grid>
      </Grid>
      {loading ? ( // Show loader when loading is true
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "50vh" }} // Ensure full height
        >
          <CircularProgress size={60} /> {/* Adjust size as needed */}
        </Grid>
      ) : (
        <>
          {tabIndex === 0 && (
            <Grid container direction="column">
              <Grid item mt={2} sx={{ maxWidth: "100%!important" }}>
                <Modifiedtable
                  rows={tabledata}
                  columns={castingcall_columns}
                  maxheight="65vh"
                  pageNumber={pageNumber}
                  pageSize={pageSize}
                  handleBlockOrUnblock={handleBlockOrUnblock}
                  handleSort={handleSort}
                  sortValue={sortValue}
                  sortBy={sortBy}
                />
              </Grid>
              <Typography variant="h6" style={{ marginTop: "20px" }}>
                Total Users Count: {userCount}
              </Typography>
              {pageCount > 1 && (
                <Grid
                  item
                  container
                  justifyContent={"center"}
                  textAlign={"center"}
                  sx={{
                    "& .pageClassName": {
                      backgroundColor: color_exports.btntext_color,
                    },
                    "& .activeClassName": {
                      backgroundColor: color_exports.primary_color,
                    },
                  }}
                >
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<RightArrow />}
                    previousLabel={<LeftArrow />} // Add the left arrow
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    renderOnZeroPageCount={null}
                    containerClassName="mainPageCont"
                    activeClassName="activeClassName"
                    activeLinkClassName="activeLinkClassName"
                    pageClassName="pageClassName"
                    forcePage={pageNumber - 1}
                    previousPageRel="prev" // Add this line
                    nextPageRel="next"     // Add this line

                  />
                </Grid>
              )}
            </Grid>
          )}

          {tabIndex === 1 && (
            <Grid container direction="column">
              <Grid item mt={2} sx={{ maxWidth: "100%!important" }}>
                {guestType === "registered" ? (
                  <div>
                    <Guestusertable
                      rows={registeredtabledata}
                      columns={regesteredcolumns}
                      maxheight="65vh"
                      pageNumber={pageNumber}
                      pageSize={pageSize}
                    />
                    <Typography variant="h6" style={{ marginTop: "20px" }}>
                      Total Guest Registered Users Count: {count}
                    </Typography>
                  </div>
                ) : guestType === "notregistered" ? (
                  <div>
                    <Guestusertable
                      rows={guestusertabledata}
                      columns={guestusers_columns}
                      maxheight="65vh"
                      pageNumber={pageNumber}
                      pageSize={pageSize}
                    />
                    <Typography variant="h6" style={{ marginTop: "20px" }}>
                      Total Guest Users Count: {guestcount}
                    </Typography>
                  </div>
                ): guestType === "existing" ? (
                  <div>
                    <Existinguserslist
                      rows={existinguserstabledata}
                      columns={existinguserscolumns}
                      maxheight="65vh"
                      pageNumber={pageNumber}
                      pageSize={pageSize}
                    />
                    <Typography variant="h6" style={{ marginTop: "20px" }}>
                      Total Existing Users Count: {existingusercount}
                    </Typography>
                  </div>
                ) : null}
              </Grid>

              {pageCount > 1 && (
                <Grid
                  item
                  container
                  justifyContent={"center"}
                  textAlign={"center"}
                  sx={{
                    "& .pageClassName": {
                      backgroundColor: color_exports.btntext_color,
                    },
                    "& .activeClassName": {
                      backgroundColor: color_exports.primary_color,
                    },
                  }}
                >
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<RightArrow />}
                    previousLabel={<LeftArrow />}  // Add left arrow
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    renderOnZeroPageCount={null}
                    containerClassName="mainPageCont"
                    activeClassName="activeClassName"
                    activeLinkClassName="activeLinkClassName"
                    pageClassName="pageClassName"
                    forcePage={pageNumber - 1}
                    previousPageRel="prev"
                    nextPageRel="next"
                  />
                </Grid>
              )}
            </Grid>
          )}

          <BlockorUnblock
            open={blockPopupOpen}
            onClose={handleBlockOrUnblockClose}
            row={selectedRow}
          />
        </>
      )}
    </>
  );
};

export default AdminUsermanagement;
