import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
  writeBatch,
  arrayRemove
} from "firebase/firestore";
import { db } from "./Firebaseconfig";
import { deleteDoc } from "firebase/firestore";

// const users_collection = collection(db, "users_info_dev"); //staging
const users_collection = collection(db, "users_info"); //final
// const conversation_collection = collection(db, "conversation_dev"); //staging
const conversation_collection = collection(db, "conversation"); //final
// const chatroom_collection = collection(db, "chat_room_dev"); //staging
const chatroom_collection = collection(db, "chat_room"); //final
const MAX_BATCH_SIZE = 500;
/**
 * Creating and updating the message
 * @param {object} param0
 */
export const create_update_message = async ({ message, selected }) => {
  try {
    const docRef = await addDoc(conversation_collection, message);
    const { blockedUsers } = message;
    message.id = docRef.id;
    let room_docref = await doc(chatroom_collection, selected?.id);
    await update_badge_count(selected);
    /** updating the recent message for two rooms */
    const updateref = await query(
      chatroom_collection,
      where("roomId", "==", selected?.roomId)
    );
    const roomsnapshot = await getDocs(updateref);
    if (!roomsnapshot.empty) {
      roomsnapshot.forEach(async (document) => {
        if (!blockedUsers.includes(document.data().userId)) {
          // Update the specific document in the batched operation
          const docRef = doc(chatroom_collection, document.id);
          updateDoc(docRef, {
            recentMessage: message,
            recentMessageTime: serverTimestamp(),
          });
        }
      });

      return { status: 1 };
    }
    // await updateDoc(room_docref, { recentMessage: message,recentMessageTime:serverTimestamp() });
  } catch (err) {
    console.log(err);
  }
};

/**
 * Updating other users badge count
 * @param {object} selected_room
 */
export const update_badge_count = async (selected_room) => {
  try {
    const roomid = selected_room?.roomId;
    const other_userids = selected_room?.memberIds.filter(
      (id) => id !== "admin"
    );
    for (let i = 0; i <= other_userids.length - 1; i++) {
      const user_ref = doc(users_collection, other_userids[i]);
      const user_doc = await getDoc(user_ref);
      let user_docval = user_doc.data();
      console.log(user_docval,"user docsvalue")
      /**Room id exists  */
      if (user_docval?.badge !== undefined && roomid in user_docval?.badge) {
        user_docval.badge[roomid] += 1;
      } else {
        user_docval = {...user_docval, badge: { [roomid]: 1 } };
      }
      await updateDoc(user_ref, user_docval);
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * Updating the own badge count
 * @param {object} selected_room
 */
export const update_ownroom_badgecount = async (selected_room) => {
  try {
    const roomid = selected_room?.roomId;
    const user_ref = doc(users_collection, "admin");
    const user_doc = await getDoc(user_ref);
    let user_docval = user_doc.data();
    /**Room id exists  */
    if (user_docval?.badge !== undefined && roomid in user_docval?.badge) {
      user_docval.badge[roomid] = 0;
    } else {
      user_docval.badge[roomid] = 0;
    }
    await updateDoc(user_ref, user_docval);
  } catch (err) {
    console.log(err);
  }
};

/**
 * Deleteing the chatrooms and also their messages
 * @param {array} selected_rooms
 */
export const delete_chatrooms = async (selected_roomids = []) => {
  try {
    const messages_query = query(
      conversation_collection,
      where("roomId", "in", selected_roomids)
    );
    const messages_res = await getDocs(messages_query);
    if (messages_res?.empty) {
      return { status: 0, message: "No messages found" };
    } else {
      const chatroomBatch = writeBatch(db);
      const room_query = query(
        chatroom_collection,
        where("roomId", "in", selected_roomids)
      );
      const chatrooms_res = await getDocs(room_query);
      for (const chatroom of chatrooms_res.docs) {
        const chatroomDocRef = doc(chatroom_collection, chatroom.id);
        const chatroomData = chatroom.data();
        const update_delete_users = await add_remove_deletedusers(chatroomData);
        const { deletedUsers, nonDeletedUsers } = update_delete_users;
        const updatedRoom = {
          ...chatroomData,
          deletedUsers,
          nonDeletedUsers,
        };
        chatroomBatch.update(chatroomDocRef, updatedRoom);
      }
      await chatroomBatch.commit();

      const commitBatch = async (batch) => {
        if (!batch.isEmpty) {
          await batch.commit();
        }
      };
      const batch = writeBatch(db);
      let batchCounter = 0;
          for(const msg of messages_res.docs){
          const messageID = msg.id;
          const data_val = msg.data();
          const del_users_res = await add_remove_deletedusers(data_val);
          let { deletedUsers, nonDeletedUsers } = del_users_res;
          const docRef = doc(conversation_collection, messageID);
          await batch.update(docRef, { deletedUsers, nonDeletedUsers });
          batchCounter++;
          if (batchCounter === MAX_BATCH_SIZE) {
            await commitBatch(batch);
            batch = writeBatch(db);
            batchCounter = 0;
          }
          }
      // Commit the batched updates
      await commitBatch(batch);
      return { status: 1, message: "User deleted successfully" };
    }
  } catch (err) {
    console.log(err);
  }
};


/**
 * 
 * @param {array} selected_roomids 
 */
export const clearchat = async (selected_roomids = []) => {
  try {
    if (!selected_roomids.length) {
      return { status: 0, message: "No room IDs provided" };
    }

    // const conversation_collection = collection(db, "conversation_dev"); //staging
    const conversation_collection = collection(db, "conversation"); //final
    // Use correct collection name
    const chatroom_collection = collection(db, "chatrooms"); // Adjust based on your Firestore structure

    // Query messages in the selected rooms
    const messages_query = query(
      conversation_collection,
      where("roomId", "in", selected_roomids),
      where("nonDeletedUsers", "array-contains", "admin")
    );

    const messages_res = await getDocs(messages_query);

    if (messages_res.empty) {
      return { status: 0, message: "No messages found" };
    }

    let batch = writeBatch(db);
    let batchCounter = 0;

    for (const msg of messages_res.docs) {
      const messageID = msg.id;
      const data_val = msg.data();

      // Remove "admin" from `nonDeletedUsers` and add it to `deletedUsers`
      let deletedUsers = [...(data_val.deletedUsers || []), "admin"];
      let nonDeletedUsers = (data_val.nonDeletedUsers || []).filter(
        (user) => user !== "admin"
      );

      const docRef = doc(conversation_collection, messageID);
      batch.update(docRef, { deletedUsers, nonDeletedUsers });

      batchCounter++;
      if (batchCounter >= MAX_BATCH_SIZE) {
        await batch.commit();
        batch = writeBatch(db);
        batchCounter = 0;
      }
    }

    // Commit any remaining batch updates
    if (batchCounter > 0) {
      await batch.commit();
    }

    // Clear recent message in the chatroom
    const chatroomBatch = writeBatch(db);
    const room_query = query(
      chatroom_collection,
      where("roomId", "in", selected_roomids)
    );

    const chatrooms_res = await getDocs(room_query);

    for (const chatroom of chatrooms_res.docs) {
      const chatroomDocRef = doc(chatroom_collection, chatroom.id);
      chatroomBatch.update(chatroomDocRef, { recentMessage: {} });
    }

    await chatroomBatch.commit();

    return { status: 1, message: "Messages cleared successfully" };
  } catch (err) {
    console.error("Error clearing chat:", err);
    return { status: 0, message: "Error clearing chat" };
  }
};
  


export const handleblockusers = async(selected_roomids =[])=>{
  try{
    const userId = "admin";
    const q = query(chatroom_collection, where("roomId", "in", selected_roomids));
    const roomsres = await getDocs(q);
    // Create a new batched write operation
    const batch = writeBatch(db);
    for (const chatroom of roomsres.docs){
      const docData = chatroom.data();
      const chatroomid = chatroom.id;
      const blockedUsers = docData?.blockedUsers ?? [];
      const nonBlockedUsers = docData?.nonBlockedUsers ?? [];
      if (blockedUsers.includes(userId)) {
        // If userId is present in blockedUsers, remove it
        const updatedBlockedUsers = blockedUsers.filter(
          (user) => user !== userId
        );

        const updated_nonblockedusers = [...nonBlockedUsers, userId];
        batch.update(chatroom.ref, { blockedUsers: updatedBlockedUsers,nonBlockedUsers:updated_nonblockedusers });
      } else {
        // If userId is not present in blockedUsers, append it
        const updatedBlockedUsers = [...blockedUsers, userId];
        const updated_nonblockedusers = nonBlockedUsers.filter((user)=> user!==userId);

        batch.update(chatroom.ref, { blockedUsers: updatedBlockedUsers, nonBlockedUsers:updated_nonblockedusers });
      }
    }
    await batch.commit();
    const room_ref = query(
      chatroom_collection,
      where("roomId", "in", selected_roomids),
      where("userId", "==", userId)
    );
    const rooms = await getDocs(room_ref);
    // console.log(rooms.docs,"sdfsdf")
    let room_val = { ...rooms.docs[0].data(), id: rooms.docs[0].id };
    return {
      status: 1,
      message: "Block status updated succesfully",
      data: room_val,
    };
  }
  catch(err){
    console.log(err);
    return {status:0,message:err}
  }
}



export const fetchBlockedUsers = async () => {
  try {
    // Query chatroom_collection where blockedUsers array contains "admin"
    const q = query(
      chatroom_collection,
      where("blockedUsers", "array-contains", "admin")
    );

    const querySnapshot = await getDocs(q);

    const blockedUsers = [];

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const roomid = data?.roomId;

      // Filter out the "admin" from the memberList and blockedUsers array
      const nonAdminMembers = data.memberList.filter(member => member.userId !== "admin");

      // Add the non-admin members to the blockedUsers array
      nonAdminMembers.forEach(member => {
        if (!blockedUsers.some(user => user.userId === member.userId)) {
          blockedUsers.push({...member,roomid});
        }
      });
    });

    // Return the blocked users array
    return {
      status: 1,
      data: blockedUsers
    };
  } catch (error) {
    console.error("Error fetching blocked users:", error);
    return {
      status: 0,
      message: error.message
    };
  }
};

export const handleblockusers1 = async (userId) => {
  try {
    const userDocRef = doc(users_collection, userId);
    await updateDoc(userDocRef, { blocked: false }); // Assuming `blocked` is a boolean field in your user document
    return { status: 1, message: "User unblocked successfully" };
  } catch (err) {
    console.log(err);
    return { status: 0, message: "Failed to unblock user" };
  }
};

const add_remove_deletedusers = async (chatroom) => {
  let deletedUsers = [...chatroom?.deletedUsers, "admin"] ?? [];
  let nonDeletedUsers =
    chatroom?.nonDeletedUsers.filter((item) => item !== "admin") ?? [];
  return { deletedUsers, nonDeletedUsers };
};
export const deleteMessage = async (messageId) => {
  try {
    // const messageRef = doc(db, "conversation_dev", messageId); //staging
    const messageRef = doc(db, "conversation", messageId); //final
    await updateDoc(messageRef, {
      nonDeletedUsers: arrayRemove("admin"), // Remove admin from nonDeletedUsers
    });
    console.log("Message deleted for admin only");
  } catch (err) {
    console.error("Error deleting message: ", err);
  }
};