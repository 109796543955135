import axios from './Apiservices/Janathaclient';
import { refreshtokenApi } from './Apiservices/Commonservices';
import './App.css';
import Router from './Routes/Router';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { isAccessTokenExpired, StorageClient } from './Helpers/Helperfunctions';
import { Envexports } from './Exports/Envexports';

const theme = createTheme({
  typography: {
    fontFamily: "'Open Sans', sans-serif",
  },
});

function App() {
  axios.interceptors.request.use(async (request) => {
    try {
      const token = StorageClient.getItem("token");
      let authorizationkey = token ? token : Envexports?.defaultauthkey;

      if (isAccessTokenExpired()) {
        const accesstoken = await refreshtokenApi();
        authorizationkey = accesstoken;
      }

      request.headers["Authorization"] = authorizationkey;
      return request;
    } catch (err) {
      return Promise.reject(err);
    }
  });

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        // Clear all local storage data
        localStorage.clear();
        // Redirect to login page
        window.location.href = "/"; // Adjust the login path if necessary
      }
      return Promise.reject(error);
    }
  );

  return (
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  );
}

export default App;
