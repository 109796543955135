import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Checkbox,
  Grid,
  Stack,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
} from "@mui/material";
import Modifiedinputfield from "../Components/Modifiedinputfield";
import Modifiedbutton from "../Components/Modifiedbutton";
import Vieweye from "../Components/Vieweye";
import Hideeye from "../Components/Hideeye";
import Forget from "../Models/Forget";
import loginimage from "../assets/Images/loginsection.png";
import logoicon from "../assets/Images/loginlogo.svg";
import { color_exports } from "../Styles/Color_exports";
import { adminloginstyles } from "../Styles/Admin_login_styles";
import { enqueueSnackbar } from "notistack";
import { adminloginapi } from "../Apiservices/Adminservices";
import { useDispatch } from "react-redux";
import { StorageClient } from "../Helpers/Helperfunctions";
import { LoginActions } from "../Store/Loginslice";
import sidebarlogo from "../assets/Images/logowithtm.svg";
import { v4 as uuidv4 } from "uuid"; // For unique deviceId

const Admin_login = () => {
  const adminstyles = adminloginstyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [forget, setForget] = useState(false);
  const [logindetails, setLogindetails] = useState({
    email: "",
    pwd: "",
    passwordstatus: false,
    isUserAdmin: "",
    deviceId: "",
  });
  const [roleError, setRoleError] = useState(false);

  // Generate a unique device ID when the component mounts
  useEffect(() => {
    const storedDeviceId = localStorage.getItem("deviceId");
    const newDeviceId = storedDeviceId || uuidv4(); // Generate new if not stored
    setLogindetails((prev) => ({ ...prev, deviceId: newDeviceId }));
    if (!storedDeviceId) {
      localStorage.setItem("deviceId", newDeviceId);
    }
  }, []);

  const handleinputchange = (event) => {
    const { name, value, type, checked } = event.target;
    setLogindetails((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleForgetClose = () => {
    setLogindetails({
      email: "",
      pwd: "",
    });
    setForget(false);
  };

  const handlePasswordKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handlelogin();
    }
  };

  const handlelogin = async () => {
    if (!logindetails.email) {
      enqueueSnackbar("Please enter your email", { variant: "error" });
      return;
    }
    if (!logindetails.pwd) {
      enqueueSnackbar("Please enter your password", { variant: "error" });
      return;
    }
    if (logindetails.isUserAdmin === "") {
      setRoleError(true);
      enqueueSnackbar("Please select your role", { variant: "error" });
      return;
    }

    try {
      const req = {
        email: logindetails.email,
        password: logindetails.pwd,
        isUserAdmin: logindetails.isUserAdmin,
        deviceId: logindetails.deviceId, // Send deviceId in API request
      };
      const loginres = await adminloginapi(req);

      if (loginres.message === "Login successful") {
        const {
          accessToken,
          isUserAdmin,
          privileges,
          refreshToken,
          preferences,
        } = loginres.data;
        StorageClient.setItem("token", accessToken);
        StorageClient.setItem("isUserAdmin", isUserAdmin);
        StorageClient.setItem("refreshtoken", refreshToken);
        dispatch(
          LoginActions.admin_login({
            adminres: { ...loginres.data, preferences },
            email: logindetails.email,
          })
        );
        if (!isUserAdmin) {
          const privilegeRoutes = {
            userManagement: "/admin/usermanagement",
            packageManagement: "/admin/packagemanagement",
            successStories: "/admin/success",
            messages: "/admin/messages",
            report: "/admin/reports",
            existingUserManagement: "/admin/Existusersmanagement",
          };

          for (const [privilege, route] of Object.entries(privilegeRoutes)) {
            if (privileges[privilege]) {
              navigate(route);
              break;
            }
          }
        } else {
          navigate("/admin");
        }
      } else {
        enqueueSnackbar("Invalid Email ID or password.", { variant: "error" });
      }
    } catch (err) {
      enqueueSnackbar("Invalid Email ID or password.", { variant: "error" });
    }
  };

  return (
    <>
      <Forget open={forget} close={handleForgetClose} />
      <Grid container className={adminstyles.logincontainer}>
        <Grid item md={6} container className={adminstyles.imglogindiv}>
          <img
            src={sidebarlogo}
            alt="loginimage"
            className={adminstyles.imglogin1}
          />
        </Grid>

        <Grid item md={6} container className={adminstyles.iconrelative}>
          <Grid item className={adminstyles.backgroundlogo}>
            <img src={logoicon} alt="logo" />
          </Grid>
          <Grid item lg={6} md={8}>
            <h4 className={adminstyles.loginText}>Login</h4>
            <Grid container direction={"column"} mt={1} rowSpacing={3}>
              <Grid item>
                <Stack spacing={1}>
                  <Grid item fontWeight={"400"} sx={{ color: color_exports.primary_color }}>
                    Email ID
                  </Grid>
                  <Grid item>
                    <Modifiedinputfield
                      size={"small"}
                      placeholder="Enter here"
                      fullWidth
                      name="email"
                      value={logindetails.email}
                      onChange={handleinputchange}
                      autoComplete="off"
                    />
                  </Grid>
                </Stack>
              </Grid>
              
              <Grid item>
                <Stack spacing={1}>
                  <Grid item fontWeight={"400"} sx={{ color: color_exports.primary_color }}>
                    Password
                  </Grid>
                  <Grid item>
                    <Modifiedinputfield
                      size={"small"}
                      placeholder="Enter here"
                      fullWidth
                      name="pwd"
                      value={logindetails.pwd}
                      onChange={handleinputchange}
                      autoComplete="off"
                      onKeyDown={handlePasswordKeyPress}
                      type={logindetails.passwordstatus ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" onClick={() => 
                            setLogindetails((prev) => ({ ...prev, passwordstatus: !prev.passwordstatus }))} 
                            style={{ cursor: "pointer" }}>
                            {logindetails.passwordstatus ? <Vieweye /> : <Hideeye />}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Stack>
              </Grid>

              <Grid item>
                <Grid
                  item
                  fontWeight={"400"}
                  sx={{ color: color_exports.primary_color }}
                >
                  Role
                </Grid>
                <FormControl fullWidth error={roleError} mb={2}>
                  <Select
                    value={logindetails.isUserAdmin}
                    name="isUserAdmin"
                    onChange={(e) => {
                      setRoleError(false);
                      handleinputchange(e);
                    }}
                    className={adminstyles.rolesty}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value={true}>Admin</MenuItem>
                    <MenuItem value={false}>Subadmin</MenuItem>
                  </Select>
                  {roleError && (
                    <FormHelperText>Please select your role</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {/* Remember me & Forgot Password */}
              <Grid
                item
                container
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Grid item>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Checkbox
                      sx={{ "&.Mui-checked": { color: "#B8003E" } }}
                      disableRipple
                    />
                    <span style={{ color: color_exports.tex_shade_color }}>
                      Remember me
                    </span>
                  </Stack>
                </Grid>
                <Grid
                  item
                  className={adminstyles.forgotsty}
                  onClick={() => setForget(true)}
                >
                  Forgot password?
                </Grid>
              </Grid>


              <Grid item container justifyContent={"center"}>
                <Modifiedbutton data={"Login"} className={adminstyles.loginbtn} onClick={handlelogin} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Admin_login;
