import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import GroupIcon from "@mui/icons-material/Group";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import ReportRoundedIcon from "@mui/icons-material/ReportRounded";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import Packageicon from "../assets/Icons/Packageicon";
import Successicon from "../assets/Icons/Successicon";
import Messageicon from "../assets/Icons/Messageicon";
import Contenticon from "../assets/Icons/Contenticon";
import { Delete } from "@mui/icons-material";

export const adminsidebar_val = [
  {
    icon: <DashboardRoundedIcon />,
    name: "Dashboard",
    link: "/admin",
    privilagename:"dashboard"
  },
  {
    icon: <GroupIcon />,
    name: "User Management",
    link: "/admin/userManagement",
    privilagename:"userManagement"
  },
  { 
    icon: <Packageicon/>,
    name: "Packages Management",
    link: "/admin/packageManagement",
    privilagename:"packageManagement"
  },
  {
    icon: <AssessmentRoundedIcon />,
    name: "Analytics",
    link: "/admin/analytics",
    privilagename:"analytics"
  },
  {
    icon: <Successicon/>,
    name: "Success stories",
    link: "/admin/success",
    privilagename:"successStories"
  },
  {
    icon: <Messageicon/>,
    name: "Messages",
    link: "/admin/messages",
    privilagename:"messages"
      },
  {
    icon: <ReportRoundedIcon />,
    name: "Reports",
    link: "/admin/reports",
    privilagename:"report"
  },
  {
    icon: <Contenticon/>,
    name: "Content Management",
    link: "/admin/contentmanagement",
    privilagename:"contentmgmnt"
  },
  {
    icon: <ManageAccountsRoundedIcon />,
    name: "Sub Admin Management",
    link: "/admin/sub_adminmanagement",
    privilagename:"subadminmgmnt"
  },
  {
    icon: <GroupIcon/>,
    name: "Existing Users Management",
    link: "/admin/Existusersmanagement",
    privilagename:"existingUserManagement"
  },
  {
    icon: <Delete />,
    name: "Delete Users",
    link: "/admin/deleteuser",
    privilagename:"deleteUser"
  },
  {
    icon: <SettingsRoundedIcon/>,
    name: "Settings",
    link: "/admin/settings",
    privilagename:"settings"
  },
];