import React from "react";
import { Dialog, Grid } from "@mui/material";
import { color_exports } from "../Styles/Color_exports";

const DeleteExistinguser = (props) => {
  const { open, onClose, onConfirm } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        p={2}
        gap={1}
      >
        <Grid item sx={{ color: color_exports.tex_shade_color }}>
          Are you sure you want to delete existing user?
        </Grid>
        <Grid container item justifyContent="right" gap={1} mt={1}>
          <Grid
            item
            sx={{
              color: color_exports.primary_color,
              fontWeight: "700",
              cursor: "pointer",
            }}
            onClick={onClose}
          >
            No
          </Grid>
          <Grid
            item
            sx={{
              color: color_exports.primary_color,
              fontWeight: "700",
              cursor: "pointer",
            }}
            onClick={onConfirm}
          >
            Yes
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DeleteExistinguser;
