import React, { useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import { modifiedtable_styles } from "../Styles/Modifiedstyles";
import { useSelector } from "react-redux";
import deleteicon from "../assets/Images/deleteicon.png";
import DeleteExistinguser from "../Models/DeleteExistinguser";
import { deleteExistingUserOffer_api } from "../Apiservices/Adminservices";
import { enqueueSnackbar } from "notistack";
const Existinguserslist = (props) => {
  const modifiedstyle = modifiedtable_styles();
  const { maxheight, rows, columns, pageNumber, pageSize, setRows } = props;
  const admin_data = useSelector((state) => state?.authentication?.data);

  // State for delete confirmation modal
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const selectedUser = useRef(null);

  // Open delete modal with selected user
  const handleOpenDeleteModal = (user) => {
    // setSelectedUser(user);
    selectedUser.current = user;
    setIsDeleteModalOpen(true);
  };

  // Close delete modal
  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    selectedUser.current = null;
    // setSelectedUser(null);
  };

  // Handle delete API call
  const handleDeleteExistinguser = async () => {
    if (!selectedUser.current) return;
  
    console.log(selectedUser.current, "existing user");
  
    try {
      const response = await deleteExistingUserOffer_api({
        email: selectedUser.current?.Email,
      });
  
      if (response?.message === "Account Deleted Successfully") {
        // Show error message instead of success
        enqueueSnackbar("Account Deleted Successfully", {
          variant: "success",
        });
      } else if (response?.message === "This is a member email") {
        // Display error message for member email
        enqueueSnackbar("This email belongs to a member and cannot be deleted", {
          variant: "error",
        });
      } else {
        console.error("Failed to delete user:", response?.message);
        enqueueSnackbar(response?.message || "Failed to delete user", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      enqueueSnackbar("An error occured when deleting user", {
        variant: "error",
      });
    }
  
    handleCloseDeleteModal();
  };

  return (
    <div>
      <TableContainer className={modifiedstyle.tablecontainer1} sx={{ maxHeight: maxheight ?? 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column, index) => (
                <TableCell
                  key={column?.id || index}
                  align={column?.align || "left"}
                  className={modifiedstyle.stickyHeader2}
                  sx={{ minWidth: column?.minWidth }}
                >
                  <div className={modifiedstyle.stickyHeader3}>
                    <span>{column?.label}</span>
                  </div>
                </TableCell>
              ))}
              {admin_data?.isUserAdmin && (
                <TableCell
                  className={modifiedstyle.stickyHeader2}
                  style={{
                    borderRight: "1px solid rgba(51, 51, 51, 0.2)",
                    fontWeight: "700",
                    fontSize: "16px",
                    textAlign: "center",
                  }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => {
              const rowIndex = index + 1;
              const backgroundColor = rowIndex % 2 === 0 ? "#efeeeefa" : "#FFFFFF";
              return (
                <TableRow key={row?.email || index} sx={{ backgroundColor }}>
                  {columns?.map((column) => {
                    const value =
                      column?.label === "S.NO"
                        ? (pageNumber - 1) * pageSize + rowIndex
                        : row?.[column?.label] ?? "";
                    return (
                      <TableCell key={column?.id || index} align={column?.align || "left"} className={modifiedstyle.tablecell1}>
                        {value}
                      </TableCell>
                    );
                  })}
                  {admin_data?.isUserAdmin && (
                    <TableCell
                      style={{
                        borderRight: "1px solid rgba(51, 51, 51, 0.2)",
                        textAlign: "center",
                      }}
                    >
                      <IconButton onClick={() => handleOpenDeleteModal(row)}>
                        <img src={deleteicon} alt="Delete" />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Delete Confirmation Modal */}
      <DeleteExistinguser
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onConfirm={handleDeleteExistinguser}
      />
    </div>
  );
};

export default Existinguserslist;
