import React, { useState } from "react";
import { Grid, Stack, Box, TextField } from "@mui/material";
import Modifiedinputfield from "../Components/Modifiedinputfield";
import Modifiedbutton from "../Components/Modifiedbutton";
import { color_exports } from "../Styles/Color_exports";
import { adminloginstyles } from "../Styles/Admin_login_styles";
import sidebarlogo from "../assets/Images/logowithtm.svg";
import { enqueueSnackbar } from "notistack";
import axios from "axios";

const DeleteUser = () => {
  const adminstyles = adminloginstyles();
  const [logindetails, setLogindetails] = useState({
    number: "",
  });
  const [otp, setOtp] = useState(Array(6).fill("")); // Array to hold OTP values
  const [isOtpSent, setIsOtpSent] = useState(false); // Flag to check if OTP is sent
  const [isAccountDeleted, setIsAccountDeleted] = useState(false); // Flag for account deletion status

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLogindetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    const newOtp = [...otp];

    // Allow only numbers and single character input
    if (/^[0-9]$/.test(value) || value === "") {
      newOtp[index] = value;
      setOtp(newOtp);

      // Focus on next input field if current one is filled
      if (value && index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      } else if (value === "" && index > 0) {
        // Focus on previous field if current field is cleared
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };

  const handleSubmit = async () => {
    try {
      const payload = { phoneNumber: logindetails.number };
      const defaultAuthKey = "ACZTq7voBzX7jERFpHJECvqd3BWCW6BC"; // Use env variable for auth key
      const backendUrl = process.env.REACT_APP_BACKEND_URL; // Use env variable for backend URL

      const headers = {
        "Content-Type": "application/json",
        Authorization: `${defaultAuthKey}`,
      };

      const response = await axios({
        method: "POST",
        url: `${backendUrl}/NYSwknRJQQMrDQy1lMnX`, // Dynamic backend URL
        // url: `${backendUrl}/deactivateAccountOtp`,
        data: payload,
        headers: headers,
      });

      // Handle the response based on the API response message
      if (response?.data?.message === "Mobile Otp send Successfully") {
        enqueueSnackbar("Mobile OTP sent successfully", { variant: "success" });
        setIsOtpSent(true); // Set flag to true to show OTP input fields
      } else {
        enqueueSnackbar("Failed to send OTP", { variant: "error" });
      }
    } catch (err) {
      console.error("Error:", err);
      enqueueSnackbar("Please Provide Valid phone Number", { variant: "error" });
    }
  };

  const handleVerifyOtp = async () => {
    const phoneNumber = logindetails.number;
    const otpString = otp.join("");
    try {
      const payload = { phoneNumber, otp: otpString };
      const defaultAuthKey = "ACZTq7voBzX7jERFpHJECvqd3BWCW6BC"; // Use env variable for auth key
      const backendUrl = process.env.REACT_APP_BACKEND_URL; // Use env variable for backend URL

      const headers = {
        "Content-Type": "application/json",
        Authorization: `${defaultAuthKey}`,
      };

      const response = await axios({
        method: "POST",
        url: `${backendUrl}/oQ2ZjFST9b`, // Dynamic backend URL
        // url: `${backendUrl}/deleteMyAccount`,
        data: payload,
        headers: headers,
      });

      // Handle the response based on the API response message
      if (response?.data?.message === "Account Deleted Successfully") {
        enqueueSnackbar("Account Deleted Successfully", { variant: "success" });
        setIsAccountDeleted(true); // Set flag to indicate account has been deleted
      } else {
        enqueueSnackbar("Failed to delete account", { variant: "error" });
      }
    } catch (err) {
      console.error("Error:", err);
      enqueueSnackbar("An error occurred while deleting account. Please try again.", { variant: "error" });
    }
  };

  return (
    <Grid container className={adminstyles.logincontainer}>
      {/* <Grid item md={6} container className={adminstyles.imglogindiv}>
        <img src={sidebarlogo} alt="loginimage" className={adminstyles.imglogin1} />
      </Grid> */}

      <Grid item md={12} container className={adminstyles.iconrelative}>
        <Grid item lg={6} md={8}>
          <Grid container direction={"column"} mt={1} rowSpacing={3}>
            <Grid item>
              <Stack spacing={1}>
                <Grid item fontWeight={"400"} sx={{ color: color_exports.primary_color }}>
                  {isAccountDeleted
                    ? "Your account has been deleted successfully."
                    : isOtpSent
                    ? "Enter OTP"
                    : "Phone Number"}
                </Grid>
                <Grid item>
                  {!isAccountDeleted && (
                    isOtpSent ? (
                      <Box display="flex" justifyContent="center" gap={1}>
                        {otp.map((data, index) => (
                          <TextField
                            key={index}
                            id={`otp-input-${index}`}
                            type="text"
                            inputProps={{
                              maxLength: 1,
                              style: {
                                textAlign: "center",
                                fontSize: "20px",
                                width: "40px",
                                height: "30px",
                                backgroundColor: "#F9CDDC",
                              },
                            }}
                            value={data}
                            onChange={(e) => handleOtpChange(e, index)}
                            variant="outlined"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  border: "none",
                                },
                              },
                            }}
                          />
                        ))}
                      </Box>
                    ) : (
                      <Modifiedinputfield
  size="medium"
  placeholder="Enter phone number"
  fullWidth
  name="number"
  value={logindetails.number}
  onChange={handleInputChange}
  mt={4}
  sx={{
    backgroundColor: "#F9CDDC", color: "#ffffff", borderRadius: "10px", // No !important needed
  }}
/>

                    )
                  )}
                </Grid>
              </Stack>
            </Grid>

            {!isAccountDeleted && (
              <Grid item mt={2} container justifyContent={"center"}>
                <Modifiedbutton
                  data={isOtpSent ? "Verify OTP" : "Submit"}
                  className={adminstyles.loginbtn}
                  onClick={isOtpSent ? handleVerifyOtp : handleSubmit}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DeleteUser;
